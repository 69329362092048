(function ($) {
  Drupal.behaviors.heroCarouselFormatter = {
    attach: function (context) {
      var $formatter = $('.hero-carousel-formatter', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $formatter.each(function () {
        var $carousel = $('.js-hero-carousel', $(this));
        var $nextArrow = $('.slick-next-button', $(this)).detach();
        var $prevArrow = $('.slick-prev-button', $(this)).detach();
        var slickCarouselSettings = {};

        if ($carousel.data('show-white-arrows')) {
          $nextArrow.find('button').addClass('white-arrows');
          $prevArrow.find('button').addClass('white-arrows');
        }

        slickCarouselSettings.nextArrow = $nextArrow.html().trim();
        slickCarouselSettings.prevArrow = $prevArrow.html().trim();
        slickCarouselSettings.dots = Boolean($carousel.data('show-dots'));
        slickCarouselSettings.autoplay = Boolean($carousel.data('slides-autoplay'));
        slickCarouselSettings.autoplaySpeed = 5000;
        $carousel.slick(slickCarouselSettings);
      });
    }
  };
})(jQuery);
